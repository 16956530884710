import { ApiParams } from '~/1st-api';
import { CrudRestClient } from '~/1st-crud';
import {
  mapOrder,
  mapOrderRaw,
  mapSentOrder,
  mapSentOrderRaw,
  Order,
  OrderRaw,
  SentOrder,
} from '../schema';

export class OrderRestClient extends CrudRestClient<Order, OrderRaw> {
  protected url = '/api/order';

  protected mapModel = mapOrder;

  protected mapModelRaw = mapOrderRaw;

  send(params: ApiParams<SentOrder>): Promise<SentOrder> {
    return this.fetch({
      method: 'post',
      endpoint: '/send',
      body: mapSentOrder(params.data),
      mapResult: mapSentOrderRaw,
    });
  }
}
