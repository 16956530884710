import { mapOrVoid } from '~/1st-api';

export type Order = {
  id?: string;
  name?: string;
  email?: string;
  message?: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type OrderRaw = {
  id?: string;
  name?: string;
  email?: string;
  message?: string;
  created_at?: string;
  updated_at?: string;
};

export function mapOrder(data: Order): OrderRaw {
  return {
    id: mapOrVoid(data.id, String),
    name: mapOrVoid(data.name, String),
    email: mapOrVoid(data.email, String),
    message: mapOrVoid(data.message, String),
    created_at: data.createdAt?.toISOString(),
    updated_at: data.updatedAt?.toISOString(),
  };
}

export function mapOrderRaw(dataRaw: OrderRaw): Order {
  return {
    id: mapOrVoid(dataRaw.id, String),
    name: mapOrVoid(dataRaw.name, String),
    email: mapOrVoid(dataRaw.email, String),
    message: mapOrVoid(dataRaw.message, String),
    createdAt: mapOrVoid(dataRaw.created_at, (date) => new Date(date)),
    updatedAt: mapOrVoid(dataRaw.updated_at, (date) => new Date(date)),
  };
}
