import { mapOrVoid } from '~/1st-api';

export type SentOrder = {
  name?: string;
  email?: string;
  message?: string;
};

export type SentOrderRaw = {
  name?: string;
  email?: string;
  message?: string;
};

export function mapSentOrder(data: SentOrder): SentOrderRaw {
  return {
    name: mapOrVoid(data.name, String),
    email: mapOrVoid(data.email, String),
    message: mapOrVoid(data.message, String),
  };
}

export function mapSentOrderRaw(dataRaw: SentOrderRaw): SentOrder {
  return {
    name: mapOrVoid(dataRaw.name, String),
    email: mapOrVoid(dataRaw.email, String),
    message: mapOrVoid(dataRaw.message, String),
  };
}
